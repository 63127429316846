
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import CoScrollableContainer from '~/components/Co/CoScrollableContainer/CoScrollableContainer'

interface Data {}

interface Methods {}

interface Computed {}

interface Props {
  benefits: boolean
  virtualCampus: boolean
  learningContent: boolean
  bannerguarantee: boolean
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  components: {
    CoScrollableContainer,
  },
  props: {
    // 給付金対象
    benefits: {
      type: Boolean,
      default: false,
    },
    mentoring: {
      // メンタリング
      type: Boolean,
      default: false,
    },
    // バーチャルキャンパス
    virtualCampus: {
      type: Boolean,
      default: false,
      required: false,
    },
    // 学習コンテンツ
    learningContent: {
      type: Boolean,
      default: false,
      required: false,
    },
    // バナー案件保証
    bannerguarantee: {
      type: Boolean,
      default: false,
    },
  },
}

export default Vue.extend(options)
