
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { getTopics } from '~/utility/fetchAPI'

interface Topic {
  attributes: {
    body: string
    url: string
    category: string
    date: string
  }
}

interface Data {
  topics: Topic[] | {}
  isDisplay: Boolean
}

interface Methods {}

interface Computed {}

interface Props {}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  data() {
    return {
      topics: [],
      isDisplay: false,
    }
  },
  computed: {
    ToYearAndMonthAndDay() {
      return (value: any) => {
        if (value) {
          const yearAndMonthAndDay = /^(\d+\/\d+\/\d+)/.exec(value)![1]
          return yearAndMonthAndDay.replaceAll('/', '.')
        } else {
          return '-'
        }
      }
    },
  },
  mounted() {
    getTopics(this.$api).then((response) => {
      this.topics = response.data
      this.isDisplay = true
    })
  },
}
export default Vue.extend(options)
