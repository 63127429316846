
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import {
  getTrialMiniCourseSchedule,
  getTrialMiniCourseScheduleTable,
} from '~/utility/fetchAPI'

interface Data {
  appointmentForm: any
  appointmentFormTable: any
}

interface Methods {}

interface Computed {}

interface Props {
  suggestCourse: boolean
  scheduleApply: boolean
  jobchange: boolean
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  props: {
    suggestCourse: {
      default: false,
    },
    scheduleApply: {
      default: false,
    },
    jobchange: {
      default: false,
    },
  },
  data() {
    return {
      appointmentForm: null,
      appointmentFormTable: null,
    }
  },
  mounted() {
    getTrialMiniCourseSchedule(this.$api, 'htmlcss-trial').then((response) => {
      this.appointmentForm = response
    })
    const body: any = {
      section: true,
    }
    getTrialMiniCourseScheduleTable(this.$api, 'htmlcss-trial', body).then(
      (response) => {
        this.appointmentFormTable = response
      }
    )
  },
}

export default Vue.extend(options)
