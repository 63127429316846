
import { getLatestCohort, getFirstOpenedWholeCohort } from '~/utility/fetchAPI'
export default {
  data() {
    return {
      cohort: null,
      firstOpenedWholeCohort: null,
    }
  },

  computed: {},

  mounted() {
    getLatestCohort(this.$api).then((response) => {
      this.cohort = response.data
    })
    getFirstOpenedWholeCohort(this.$api).then((response) => {
      this.firstOpenedWholeCohort = response.data
    })
  },
}
