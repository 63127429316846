const CONSTANT_CONTENT = {
  keywords: 'プログラミング, ブートキャンプ, エンジニア, ruby on rails, パーソナルメンター',
  description: '【テックアカデミー(TechAcademy)】受講者数No.1のオンラインプログラミングスクール。通過率10%を突破した現役エンジニアからマンツーマンで学べる転職や副業に活かせるスキルを最短4週間で習得。',
  twitterCard: 'summary_large_image',
  twitterSite: '@techacademy',
  twitterCreator: '@techacademy',
  twitterTitle: 'TechAcademy [テックアカデミー]',
  twitterDescription: '大切なのは、誰から学ぶか。選抜された現役エンジニアから学ぶならテックアカデミー。',
  twitterImage: '/_nuxt/ogp/og-image.jpg',
  ogType: 'website',
  ogTitle: 'TechAcademy [テックアカデミー]',
  ogDescription: '大切なのは、誰から学ぶか。選抜された現役エンジニアから学ぶならテックアカデミー。',
  ogUrl: '',
  ogImage: '/_nuxt/ogp/og-image.jpg'
}

const CONSTANT_CONTENT_YTA = {
  keywords: 'プログラミング, ブートキャンプ, エンジニア, パーソナルメンター',
  description: 'LINEヤフーテックアカデミー',
  twitterCard: 'summary_large_image',
  twitterSite: '@techacademy',
  twitterCreator: '@techacademy',
  twitterTitle: 'LINEヤフーテックアカデミー | テックアカデミー',
  twitterDescription: 'LINEヤフーテックアカデミー',
  twitterImage: '/_nuxt/ogp/yate/og-image.jpg',
  ogType: 'website',
  ogTitle: 'LINEヤフーテックアカデミー | テックアカデミー',
  ogDescription: 'LINEヤフーテックアカデミー',
  ogUrl: '',
  ogImage: '/_nuxt/ogp/yate/og-image.jpg'
}

interface config {
  keywords?: string
  description?: string
  twitterCard?: string
  twitterSite?: string
  twitterCreator?: string
  twitterTitle?: string
  twitterDescription?: string
  twitterImage?: string
  ogType?: string
  ogTitle?: string
  ogDescription?: string
  ogUrl?: string
  ogImage?: string
  noIndexNofollow?: boolean
}

// Metatag の返答する
export const metaTags = (config: config, host: string) => {
  let meta = [
        {
          hid: 'keywords',
          name: 'keywords',
          // @ts-ignore
          content: config.keywords || config.keyword || CONSTANT_CONTENT.keywords,
        },
        {
          hid: 'description',
          name: 'description',
          content: config.description || CONSTANT_CONTENT.description,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: config.twitterCard || CONSTANT_CONTENT.twitterCard,
        },
        { hid: 'twitter:site', name: 'twitter:site', content: config.twitterSite || CONSTANT_CONTENT.twitterSite },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: config.twitterCreator || CONSTANT_CONTENT.twitterCreator,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: config.twitterTitle || CONSTANT_CONTENT.twitterTitle,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: config.twitterDescription || CONSTANT_CONTENT.twitterDescription
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `${host}${config.twitterImage || CONSTANT_CONTENT.twitterImage}`,
        },
        { hid: 'og:type', property: 'og:type', content: config.ogType || CONSTANT_CONTENT.ogType },
        {
          hid: 'og:title',
          property: 'og:title',
          content: config.ogTitle || CONSTANT_CONTENT.ogTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: config.ogDescription || CONSTANT_CONTENT.ogDescription
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${host}${config.ogUrl || CONSTANT_CONTENT.ogUrl}`
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${host}${config.ogImage || CONSTANT_CONTENT.ogImage}`,
        },
      ]
  if (config.noIndexNofollow) {
    meta.push({
      hid: "robots",
      name: "robots",
      content: "noindex, nofollow"
    })
  }
  return meta
}

// Metatag の返答する
export const metaLYTATags = (config: config, host: string) => {
  let meta = [
        {
          hid: 'keywords',
          name: 'keywords',
          // @ts-ignore
          content: config.keywords || config.keyword || CONSTANT_CONTENT_YTA.keywords,
        },
        {
          hid: 'description',
          name: 'description',
          content: config.description || CONSTANT_CONTENT_YTA.description,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: config.twitterCard || CONSTANT_CONTENT_YTA.twitterCard,
        },
        { hid: 'twitter:site', name: 'twitter:site', content: config.twitterSite || CONSTANT_CONTENT_YTA.twitterSite },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: config.twitterCreator || CONSTANT_CONTENT_YTA.twitterCreator,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: config.twitterTitle || CONSTANT_CONTENT_YTA.twitterTitle,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: config.twitterDescription || CONSTANT_CONTENT_YTA.twitterDescription
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `${host}${config.twitterImage || CONSTANT_CONTENT_YTA.twitterImage}`,
        },
        { hid: 'og:type', property: 'og:type', content: config.ogType || CONSTANT_CONTENT_YTA.ogType },
        {
          hid: 'og:title',
          property: 'og:title',
          content: config.ogTitle || CONSTANT_CONTENT_YTA.ogTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: config.ogDescription || CONSTANT_CONTENT_YTA.ogDescription
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${host}${config.ogUrl || CONSTANT_CONTENT_YTA.ogUrl}`
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${host}${config.ogImage || CONSTANT_CONTENT_YTA.ogImage}`,
        },
      ]
  if (config.noIndexNofollow) {
    meta.push({
      hid: "robots",
      name: "robots",
      content: "noindex, nofollow"
    })
  }
  return meta
}
