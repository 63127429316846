import * as yup from 'yup'

const ErrorMessages = {
  required: '必須項目です。',
  half_num: '半角数字で入力して下さい。',
  full_num: '全角数字で入力して下さい。',
  hiragana: 'ひらがなで入力してください。',
  full_kana: '全角カタカナで入力してください。',
  half_kana: '半角カタカナで入力してください。',
  valid_date: '正しい年月日を入力してください',
  tel: '電話番号は不正な値です',
  email: 'メールアドレスの形式が正しくありません。',
  password:
    '英文字（大文字、小文字）、数字をそれぞれ最低一文字以上入れた8〜16文字で入力してください。',
  match: '入力内容が異なります。',
  url: '「http://」もしくは「https://」から始まるURLを入力してください。',
  postal_code: '7桁の数字を入力してください',
}

const Regex = {
  half_num: /^[0-9]+$/,
  half_alphanumeric: /^[0-9a-zA-Z]+$/,
  full_num: /^[０-９]+$/,
  hiragana: /^[ぁ-んー]*$/,
  full_kana: /^[ァ-ヶー]*$/,
  half_kana: /^[ｦ-ﾟ]*$/,
  tel: /^(0{1}\d{9,10})$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  password: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z0-9!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]{8,16}$/,
  dependent_char: /^(?!.*(\u2460|\u2461|\u2462|\u2463|\u2464|\u2465|\u2466|\u2467|\u2468|\u2469|\u246A|\u246B|\u246C|\u246D|\u246F|\u2470|\u2471|\u2472|\u2473|\u2160|\u2161|\u2162|\u2163|\u2164|\u2165|\u2166|\u2167|\u2168|\u2169|\u3349|\u3314|\u3322|\u334D|\u3318|\u3327|\u3303|\u3336|\u3351|\u3357|\u330D|\u3326|\u3323|\u332B|\u334A|\u333B|\u339C|\u339D|\u339E|\u338E|\u338F|\u33C4|\u33A1|\u337B|\u301D|\u301F|\u2116|\u33CD|\u2121|\u32A4|\u32A5|\u32A6|\u32A7|\u32A8|\u3231|\u3232|\u3239|\u337E|\u337D|\u337C|\u222E|\u221F|\u22BF|\u2170|\u2171|\u2172|\u2173|\u2174|\u2175|\u2176|\u2177|\u2178|\u2179|\uFFE2|\uFFE4|[\uD800-\uDBFF][\uDC00-\uDFFF])).*$/,
  url: /^(https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/,
}

export const required = (value) => (value ? undefined : ErrorMessages.required)
export const requiredArray = value => (value.length !== 0 ? undefined : ErrorMessages.required) 
export const requiredLong = (value) =>
  value ? undefined : ErrorMessages.required_long
export const requiredOrZero = (value) =>
  value || value === 0 ? undefined : ErrorMessages.required

export const halfNum = (value) =>
  value && !Regex.half_num.test(value) ? ErrorMessages.half_num : undefined

export const fullNum = (value) =>
  value && !Regex.full_num.test(value) ? ErrorMessages.full_num : undefined

export const halfAlphanumeric = (value) =>
  value && !Regex.half_alphanumeric.test(value)
    ? ErrorMessages.half_alphanumeric
    : undefined

export const hiragana = (value) =>
  value && !Regex.hiragana.test(value) ? ErrorMessages.hiragana : undefined

export const fullKana = (value) =>
  value && !Regex.full_kana.test(value) ? ErrorMessages.full_kana : undefined

export const halfKana = (value) =>
  value && !Regex.half_kana.test(value) ? ErrorMessages.half_kana : undefined

export const validDate = (y, m, d) => {
  const dt = new Date(y, m - 1, d)
  return !(
    dt.getFullYear() === Number(y) &&
    dt.getMonth() === Number(m - 1) &&
    dt.getDate() === Number(d)
  )
    ? ErrorMessages.valid_date
    : undefined
}
export const tel = (value) =>
  value && !Regex.tel.test(value) ? ErrorMessages.tel : undefined

export const match = (target) => (value) =>
  value === target ? undefined : ErrorMessages.match

export const dependentChar = (value) => {
  const testValue = String(value).replace(/\n|\r\n/g, '')
  return value && !Regex.dependent_char.test(testValue)
    ? ErrorMessages.dependent_char
    : undefined
}

export const email = (value) => {
  const schema = yup.string().email()
  return schema.isValidSync(value) ? undefined : "有効なメールアドレスではありません"
}
