
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { daysOfWeek } from '~/utility/utility'
import CoScrollableContainer from '~/components/Co/CoScrollableContainer/CoScrollableContainer.vue'

interface Data {
  appointmentDate: Date | null
}

interface Methods {
  create2daysArray: (date: Date | null) => Date[] | null[]
  sendAppointmentDate: (time: string, index: number) => void
  dateToStr: (date: Date) => string
}

interface Computed {
  table: boolean[][]
  appointmentDays: Date[] | null[]
  appintmentTimes: (hour: number) => any
  appointmentTable: boolean[][]
}

interface Props {
  appointmentForm: any
  appointmentFormTable: any
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  props: {
    appointmentForm: {
      default: null,
    },
    appointmentFormTable: {
      default: null,
    },
  },
  data() {
    return {
      appointmentDate: null,
    }
  },
  mounted() {},
  computed: {
    table() {
      if (!this.appointmentFormTable) return null
      return this.appointmentFormTable.table
    },
    appointmentDays() {
      if (!this.appointmentForm) return this.create2daysArray(null)
      const submittedBeginsAtDate = new Date(
        this.appointmentForm.submitted_begins_at
      )
      return this.create2daysArray(submittedBeginsAtDate)
    },
    appintmentTimes() {
      return function (hour) {
        const rangeFrom0To31 = Array.from(Array(32), (_, k) => k)
        const times = rangeFrom0To31.map((num, i) => {
          if (i % 2 === 0) {
            const n = 8 + num / 2
            if (n === hour) {
              return {
                num,
                time: `${n}:00`,
              }
            } else {
              return {}
            }
          } else {
            const n = Math.floor(8 + (num - 1) / 2)
            if (n === hour) {
              return {
                num,
                time: `${n}:30`,
              }
            } else {
              return {}
            }
          }
        })
        return times.filter((obj) => Object.keys(obj).length)
      }
    },
    appointmentTable() {
      if (!this.table) {
        const initialValue = [...Array(32)].map(() => {
          return [...Array(2)].map(() => {
            return false
          })
        })
        return initialValue
      } else {
        return this.table
      }
    },
  },
  methods: {
    create2daysArray(date: Date | null): Date[] | null[] {
      if (!date) return [...Array(2)].map(() => null)
      const today = new Date(date) as Date
      if (today.getHours() >= 22) {
        today.setDate(today.getDate() + 1)
      }
      const days = [...Array(2)].map((_, index) => {
        const day = new Date()
        day.setDate(today.getDate() + index)
        day.setHours(0)
        day.setMinutes(0)
        day.setSeconds(0)
        return day
      })
      return days
    },
    sendAppointmentDate(time, index) {
      const day = this.appointmentDays[index]
      if (!day) return null
      const [hour, minute] = time.split(':')
      const date = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        Number(hour),
        Number(minute)
      )
      this.appointmentDate = date
    },
    dateToStr(date) {
      if (date == null) return '-/- (-)'
      return `${date.getMonth() + 1}/${date.getDate()} (${daysOfWeek(date)})`
    },
  },
  components: {
    CoScrollableContainer,
  },
}

export default Vue.extend(options)
