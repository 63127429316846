
import Vue from 'vue'
import type { PropOptions } from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import type { StringDate } from 'types/constantType'

interface Data {
  leftTime: number
}

interface Methods {
  countdown(): void
}

interface Computed {
  seconds: number
  minutes: number
  hours: number
  days: number
}

interface Props {
  entryDiscountTimeLimit: StringDate
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  data() {
    return {
      leftTime: 0,
    }
  },
  filters: {
    formatTime(value: number) {
      if (value < 10) {
        return '0' + value
      }
      return value
    },
  },
  computed: {
    seconds() {
      return Math.floor((this.leftTime / 1000) % 60)
    },
    minutes() {
      return Math.floor((this.leftTime / 1000 / 60) % 60)
    },
    hours() {
      return Math.floor((this.leftTime / (1000 * 60 * 60)) % 24)
    },
    days() {
      return Math.floor(this.leftTime / (1000 * 60 * 60 * 24))
    },
  },
  props: {
    entryDiscountTimeLimit: {
      type: String,
      default: null,
    } as PropOptions<StringDate>,
  },
  watch: {
    entryDiscountTimeLimit: {
      immediate: true,
      handler(newValue, _) {
        if (newValue) {
          this.leftTime = Date.parse(newValue) - new Date().getTime()
          setTimeout(this.countdown, 1000)
        }
      },
    }
  },
  methods: {
    countdown(): void {
      this.leftTime -= 1000
      if (this.leftTime > 0) {
        setTimeout(this.countdown, 1000)
      } else {
        this.leftTime = 0
      }
    },
  },
}

export default Vue.extend(options)
