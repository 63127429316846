
import { throttle } from 'throttle-debounce'
const isScrollable = (el, isVertical = false) => {
  if (isVertical) {
    return el.clientHeight < el.scrollHeight
  }
  return el.clientWidth < el.scrollWidth
}
const canScrollFrom = (el, isVertical = false) => {
  if (isVertical) {
    return el.scrollTop !== 0
  }
  return el.scrollLeft !== 0
}
const canScrollTo = (el, isVertical = false) => {
  if (isVertical) {
    return el.scrollTop + el.offsetHeight !== el.scrollHeight
  }
  return el.scrollLeft + el.offsetWidth !== el.scrollWidth
}
const getThemeColor = (theme) => {
  switch (theme) {
    case 'light':
      return '#fff'
    case 'dark':
      return '#111'
  }
  return '#fff'
}
const getThemeBackgroundColor = (theme) => {
  switch (theme) {
    case 'light':
      return 'rgba(0, 0, 0, .5)'
    case 'dark':
      return 'rgba(255, 255, 255, .8)'
  }
  return 'rgba(0, 0, 0, .5)'
}
export default {
  props: {
    size: {
      type: Number,
      default: 100,
    },
    theme: {
      type: String,
      default: 'light',
    },
    label: {
      type: String,
      default: 'scrollable',
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    shadowFromX: {
      type: String,
      default: '0',
    },
    shadowToX: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      once: false,
      notificationEnabled: false,
      notificationStyle: {
        top: 0,
        left: 0,
        width: this.size + 'px',
        height: this.size + 'px',
        color: getThemeColor(this.theme),
        backgroundColor: getThemeBackgroundColor(this.theme),
        borderRadius: this.size * 0.1 + 'px',
      },
      messageStyle: {
        fontSize: this.size * 0.12 + 'px',
      },
      scrollableFrom: false,
      scrollableTo: false,
      scrollHandler: null,
    }
  },
  computed: {
    isScrollable() {
      return this.scrollableFrom || this.scrollableTo
    },
  },
  mounted() {
    this.initialize()
  },
  beforeDestroy() {
    window.removeEventListener('load', this._updateScrollableHandler)
    if (this.scrollHandler) {
      window.removeEventListener('scroll', this.scrollHandler)
    }
  },
  methods: {
    initialize() {
      const elRoot = this.$refs.root
      this.reset()
      // If the container element is displayed inside the window when the window is loaded, the notification element display.
      window.addEventListener('load', this._updateScrollableHandler)
      const handleScroll = throttle(150, () => {
        if (this._updateScrollable(elRoot)) {
          window.removeEventListener('scroll', handleScroll)
          this.scrollHandler = null
        }
      })
      window.addEventListener('scroll', handleScroll)
      this.scrollHandler = handleScroll
    },
    reset() {
      const elRoot = this.$refs.root
      this.once = false
      this.notificationStyle.top = (elRoot.clientHeight - this.size) / 2 + 'px'
      this.notificationStyle.left = (elRoot.clientWidth - this.size) / 2 + 'px'
      if (isScrollable(elRoot, this.isVertical)) {
        this.scrollableFrom = false
        this.scrollableTo = true
      }
      this._updateScrollable(elRoot)
    },
    _updateScrollable(elRoot) {
      const rect = elRoot.getBoundingClientRect()
      const isInsideWindow = rect.top < window.innerHeight && rect.top > 0
      if (!this.once && isInsideWindow) {
        this.once = true
        this.notificationEnabled = isScrollable(elRoot, this.isVertical)
      }
      return isInsideWindow
    },
    _updateScrollableHandler() {
      const elRoot = this.$refs.root
      if (elRoot) {
        this._updateScrollable(elRoot)
      }
    },
    scroll(e) {
      this.notificationEnabled = false
      const el = e.target
      if (isScrollable(el, this.isVertical)) {
        this.scrollableFrom = canScrollFrom(el, this.isVertical)
        this.scrollableTo = canScrollTo(el, this.isVertical)
      }
    },
  },
}
