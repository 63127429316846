// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/Te/TeCommon/Banner/Timelimit/bg-countdown-1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/images/Te/TeCommon/Banner/Timelimit/bg-countdown-2.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".te-bg-time-limit-banner[data-v-0e5c56a8]{background-color:#ff802b;background-position:3%,97%;background-repeat:no-repeat;background-size:auto,auto}@media(min-width:768px){.te-bg-time-limit-banner[data-v-0e5c56a8]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}.te-time-limit[data-v-0e5c56a8] .btn-secondary{background:none;border:none;color:#ff3;font-size:1.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
