// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/Te/TeTop/Cover/Consultation/cover-man-sp.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/images/Te/TeTop/Cover/Consultation/cover-man-tablet.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../../assets/images/Te/TeTop/Cover/Consultation/cover-man-pc.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".te-cover[data-v-81af7fc2]{background-color:#cee2e9}.te-cover-bg[data-v-81af7fc2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100% 100%;background-size:cover;height:25rem}@media(min-width:576px){.te-cover-bg[data-v-81af7fc2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%}}@media(min-width:992px){.te-cover-bg[data-v-81af7fc2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:calc(50% - 3.75rem);background-repeat:no-repeat}}@media(min-width:1200px){.te-cover-bg[data-v-81af7fc2]{background-position:50%;margin:auto;max-width:120rem}}@media(max-width:899px){.te-cover-title-bg[data-v-81af7fc2]{background:linear-gradient(90deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))}}.te-cover-title-bg-large[data-v-81af7fc2]{height:11.625rem}@media(min-width:900px){.te-cover-title-bg-large[data-v-81af7fc2]{background:linear-gradient(90deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));left:0;top:2.5rem;width:50%;z-index:0}}@media(min-width:992px){.te-cover-title-bg-large[data-v-81af7fc2]{height:12.375rem}}.te-title[data-v-81af7fc2]{line-height:1.5}.te-main-text[data-v-81af7fc2]{font-size:1rem}@media(min-width:768px){.te-main-text[data-v-81af7fc2]{font-size:1.75rem}}.te-button[data-v-81af7fc2]{width:19.437rem}.te-cover-annotation[data-v-81af7fc2]{background:linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.8) 50%,hsla(0,0%,100%,0));width:19.437rem}.co-aspectratio-attr[data-v-81af7fc2]{aspect-ratio:attr(width)/attr(height)}.te-text-counseling[data-v-81af7fc2]{color:#13cd8a}.te-cover-icon[data-v-81af7fc2]{color:#13cd8a;margin-top:.125rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
