import { ScriptConfig } from '~/constants/scriptUtilities/scriptTypes'

export const scriptTag = ({
    gtm = true, gtmLyta = false, gtag = true, globalSite = true,
    facebook = false, criteoItem = undefined, gtag_page_view = false,
    criteoHome = false, criteoCart = false, criteoTag = false, criteoScript = false,
    remarketingTag = false, dynamicFacebookAd = false, facebookPixelCode = undefined, facebookEventTag = false,
    zendeskTag = true, karteBlock = true,
    reproBoosterTag = process.env.NODE_ENV == 'staging'
  }: ScriptConfig): any => {

  var GTM_AD_ID = `${process.env.NODE_ENV == 'production' ? 'AW-1006744060' : 'AW-0000000000'}`

  return {
    script: [
      // layouts/google_tag_manager_head
      ( gtm ? { hid: 'gtmData', innerHTML: innerHTMLForGtmData() } : { hid: 'gtmData' } ),
      ( gtm ? { hid: 'gtmHead', innerHTML: innerHTMLForGtmHead(process.env.NODE_ENV === 'production' ? 'GTM-57STXX' : (process.env.GTM_ID || 'GTM-01ABCD') ) } : { hid: 'gtmHead' } ),
      ( gtmLyta ? { hid: 'gtmLytaHead', innerHTML: innerHTMLForGtmHead(process.env.NODE_ENV === 'production' ? 'GTM-M993DTTR' : 'GTM-01LYTA') } : { hid: 'gtmLytaHead' }),
      //
      ( gtag ? { hid: 'gtag', src: `https://www.googletagmanager.com/gtag/js?id=${GTM_AD_ID}`, async: true } : { hid: 'gtag' } ),
      // layouts/ad/google/_ad_global_site_tag.erb
      ( globalSite ? { hid: 'globalSite', innerHTML: innerHTMLForGlobalSite() } : { hid: 'globalSite' }),
      ( criteoScript ? criteoScriptObject() : { hid: 'criteoScriptObject' }),
      // layouts/ad/criteo/product_detail_page_tag
      ( criteoItem ? { hid: 'criteoItemTag', innerHTML: innerHTMLForCriteoItem(criteoItem.deviceSize!, criteoItem.item!), type: 'text/javascript'} : { hid: 'criteoItemTag' }),
      // layouts/ad/facebook/base_pixel
      ( facebookPixelCode ? { hid: 'facebookPixelCode', innerHTML: innerHTMLForFacebookPixelCode(facebookPixelCode) } : { hid: 'facebookPixelCode' }),
      // layouts/zendesk_tag
      ( zendeskTag ? { hid: 'zenDesk', id: 'ze-snippet', src: `https://static.zdassets.com/ekr/snippet.js?key=${process.env.NODE_ENV == 'production' ? 'be8eb876-5428-4daa-a1f3-862e85def3a6' : 'abcdefg-1234-abcd-abcd-12345678'}`, async: true, type: 'text/javascript' } : { hid: 'zenDesk' }),
      ( zendeskTag ? { hid: 'zenDeskScript', innerHTML: innerHTMLForZenDesk(), pbody: true } : { hid: 'zenDeskScript' }),
      // lauouts/ad/google/remarkting_tag
      ( remarketingTag ? { hid: 'remarketingTag', innerHTML: innerHTMLForRemarketingTag(remarketingTag) } : { hid: 'remarketingTag' }),
      ( karteBlock ? { hid: 'karteBlock', src: `https://cdn-blocks.karte.io/${process.env.NODE_ENV === 'production' ? 'ec7b3861cfb54f269f118bfed8d152f6' : 'abcd0123'}/builder.js`} : {}),
      ( reproBoosterTag ? { hid: 'reproBoosterTag', innerHTML: innerHTMLForReproBooster() } : { hid: 'reproBoosterTag' } )
    ],

    noscript: [
      ( gtm ? { hid: 'gtmBody', innerHTML: innerHTMLForGtmBody(process.env.NODE_ENV === 'production' ? 'GTM-57STXX' : (process.env.GTM_ID || 'GTM-01ABCD') ), pbody: true } : { hid: 'gtmBody' }),
      ( gtmLyta ? { hid: 'gtmLytaBody', innerHTML: innerHTMLForGtmBody(process.env.NODE_ENV === 'production' ? 'GTM-M993DTTR' : 'GTM-01LYTA'), pbody: true } : { hid: 'gtmLytaBody', pbody: true }),
      // facebook pixel code
      ( facebookPixelCode ? { hid: 'facebookPixelCode', innerHTML: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${process.env.NODE_ENV == 'production' ? '321954224680957' : '000000000000000000'}&ev=PageView&noscript=1" />` } : { hid: 'facebookPixelCode' }),
    ],

    __dangerouslyDisableSanitizersByTagID: {
      gtmData: ['innerHTML'],
      gtmHead: ['innerHTML'],
      gtmLytaHead: ['innerHTML'],
      gtmBody: ['innerHTML'],
      gtmLytaBody: ['innerHTML'],
      globalSite: globalSite ? ['innerHTML'] : [],
      facebookPixelCode: ['innerHTML'],
      remarketingTag: ['innerHTML'],
      criteoItemTag: ['innerHTML'],
      reproBoosterTag: ['innerHTML'],
      zenDeskScript: ['innerHTML'],
    }
  }
}

const innerHTMLForGlobalSite = (): string => {
  var GTM_AD_ID = `${process.env.NODE_ENV == 'production' ? 'AW-1006744060' : 'AW-0000000000'}`;
  var GTM_UA_ID = `${process.env.NODE_ENV == 'production' ? 'UA-8189758-20' : 'UA-0000000-00'}`;

  return `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "${GTM_AD_ID}", { 'allow_enhanced_conversions': true });
    gtag('config', "${GTM_UA_ID}")
  `
}

const innerHTMLForCriteoItem = (deviceSize: 'd' | 't' | 'm', itemName?: string): string => {
  if (itemName == null) return ''
  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
      { event: "setAccount", account: ${process.env.NODE_ENV == 'production' ? '30101' : '00000'} },
      { event: "setSiteType", type: "${deviceSize}" },
      { event: "viewItem", item: "${itemName}" }
    );
  `
}

const criteoScriptObject = (): any => {
  return {
    hid: 'criteo',
    src: `//${process.env.NODE_ENV == 'production' ? 'static.criteo.net' : 'localhost'}/js/ld/ld.js`,
    async: true,
    type: 'text/javascript',
  }
}

const innerHTMLForCriteoHome = (deviceSize: 'd' | 't' | 'm'): string => {
  return `
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(
    { event: "setAccount", account: 30101 },
    { event: "setSiteType", type: "${deviceSize}" },
    { event: "viewHome" }
    );
  `
}

const innerHTMLForRemarketingTag = (element?: any): string | null => {
  var AD_ID = `${process.env.NODE_ENV == 'production' ? 'AW-100674406' : 'AW-0000000000'}`
  if (element) {
    return `
      gtag('event', 'page_view', {
        'send_to': '${AD_ID}',
        'value': '${element.ad_item_price}',
        'items': [{
          'id': '${element.ad_item_id}',
          'google_business_vertical': 'education'
        }]
      });
    `
  } else {
    return null
  }
}

const innerHTMLForDynamicFacebookAd = (element?: any): string | null => {
  if (!element) return null
  return `
    fbq('track', 'ViewContent', {
      contents: [{id: '${element.ad_item_id}', quantity: 1}],
      content_type: 'product'
    });
  `
}

const innerHTMLForFacebookPixelCode = (facebookEventTag?: any) => {
  return `
    window.addEventListener('load', function() {
      ${facebookEventTag}
    });
  `
}

const innerHTMLForZenDesk = () => {
  return `
    window.addEventListener('load', function() {
      zE(function()
      {zE.hide();});
    });
  `
}

const innerHTMLForReproBooster = () => {
  return `
    (function() {
      var trackerName = null;
      var dimensionName = 'dimension11';
      !function(o,e,n){var r=[];if(window.reproio)console.info("Repro Web SDK was loaded more than once");else{window.reproio=function(){r.push(arguments)};var i=o.createElement(e),t=o.getElementsByTagName(e)[0];i.src="https://cdn.reproio.com/web/poc/common/repro-sdk.min.js",i.async=!0,i.crossOrigin="",i.onload=function(){window.reproio("setSnippetVersion","2.1"),r.forEach(function(o){window.reproio.apply(window.reproio,o)})},t.parentNode.insertBefore(i,t)}}(document,"script");
      reproio("setup", "530ab43b-4df5-4fbb-8d4d-4f7becc7102e", { precacher_ga_setting: { trackerName: trackerName, dimensionName: dimensionName } });
    })();
  `
}

const innerHTMLForGtmData = () => {
  return `
    (function() {
      var gtm_user_id = window.localStorage.getItem('gtm_user_id');
      if (gtm_user_id) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'user_id': gtm_user_id
        });
      }
    })();
  `
}

const innerHTMLForGtmHead = (gtmId: string) => {
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({
      'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');
  `
}

const innerHTMLForGtmBody = (gtmId: string) => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
}
