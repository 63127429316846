
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import VueSlickCarousel from 'vue-slick-carousel'
import { getCurrentPromotion } from '~/utility/fetchAPI'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

/* eslint-disable camelcase */
interface Promotion {
  attributes: {
    alert_limited: string
    title_image_url: string
    title_image_alt: string
    background_image_url: string
    background_position: string
    text_color: string
    text_bg_color: string
    ends_at: string
  }
}
/* eslint-enable */

interface Data {
  currentPromotion: Promotion | null
}

interface Methods {
  assetsUrl: (url: string) => string
}

interface Computed {
  alertLimited: string | null
  titleImageUrl: string | null
  titleImageAlt: string | null
  backgroundImageUrl: string | null
  backgroundPosition: string | null
  textColor: string | null
  textBgColor: string | null
  endsAt: string
  dateEndsAt: Date
  endsDays: number
  endsMonth: number
}

interface Props {
  topPage: Boolean
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  props: {
    topPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      settings: {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              centerMode: true,
              slidesToShow: 1,
              centerPadding: '25px',
            },
          },
        ],
      },
      currentPromotion: null,
    }
  },
  computed: {
    alertLimited() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.alert_limited
        : null
    },
    titleImageUrl() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.title_image_url
        : null
    },
    titleImageAlt() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.title_image_alt
        : null
    },
    backgroundImageUrl() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.background_image_url
        : null
    },
    backgroundPosition() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.background_position
        : null
    },
    textColor() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.text_color
        : null
    },
    textBgColor() {
      return this.currentPromotion
        ? this.currentPromotion.attributes.text_bg_color
        : null
    },
    endsAt() {
      return this.currentPromotion?.attributes?.ends_at || '2050/01/01 02:00:00'
    },
    dateEndsAt() {
      return new Date(this.endsAt)
    },
    endsDays() {
      if (this.dateEndsAt.getDate() === 1) {
        const yesterday = new Date(this.dateEndsAt)
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday.getDate()
      }
      return this.dateEndsAt.getDate() - 1
    },
    endsMonth() {
      if (this.dateEndsAt.getDate() === 1) {
        const yesterday = new Date(this.dateEndsAt)
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday.getMonth() + 1
      }
      return this.dateEndsAt.getMonth() + 1
    },
  },
  methods: {
    assetsUrl(url) {
      return process.env.assetsHost + url
    },
  },
  created() {
    getCurrentPromotion(this.$api).then((response) => {
      this.currentPromotion = response.data
    })
  },
  components: {
    VueSlickCarousel,
  },
}
export default Vue.extend(options)
