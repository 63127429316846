
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'

interface Data {}

interface Methods {}

interface Computed {}

interface Props {
  paid: boolean
  appliedHtmlcssTrial: boolean
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  props: {
    paid: {
      type: Boolean,
      required: true,
    },
    appliedHtmlcssTrial: {
      type: Boolean,
      required: true,
    },
  },
}

export default Vue.extend(options)
