
import Vue from 'vue'
import type { PropOptions } from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import { formatDayOfWeek } from '~/utility/utility'
import { Cohort } from '~/types/constantType'

interface Data {
  leftTime: number | null
}

interface Methods {}

interface Computed {
  closesAt: Date | null
  yesterdayClosesAt: Date | null
  dayOfWeek: string | '-'
  month: number | '-'
  hours: number | '-'
  day: number | '-'
  showNotification: string | null
}

interface Props {
  cohort: Cohort
}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  data() {
    return {
      leftTime: null,
    }
  },
  filters: {
    formatTime(value: number) {
      if (value < 10) {
        return '0' + value
      }
      return value
    },
  },
  props: {
    cohort: {
      type: Object,
    } as PropOptions<Cohort>,
  },
  computed: {
    closesAt() {
      return this.cohort ? new Date(this.cohort.attributes.closes_at) : null
    },
    yesterdayClosesAt() {
      if (this.closesAt) {
        const yesterday = new Date(this.closesAt)
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday
      } else {
        return null
      }
    },
    hours() {
      return this.closesAt ? this.closesAt.getHours() + 24 : '-'
    },
    day() {
      return this.yesterdayClosesAt ? this.yesterdayClosesAt.getDate() : '-'
    },
    month() {
      return this.yesterdayClosesAt
        ? this.yesterdayClosesAt.getMonth() + 1
        : '-'
    },
    dayOfWeek() {
      return this.yesterdayClosesAt
        ? formatDayOfWeek(this.yesterdayClosesAt)
        : '-'
    },
    showNotification() {
      if (this.closesAt) {
        const now = new Date()
        const leftDays =
          (this.closesAt.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
        if (leftDays > 0 && leftDays < 1) {
          return 'today'
        } else if (leftDays > 1 && leftDays < 3) {
          return 'aLittleLeft'
        } else {
          return null
        }
      } else {
        return null
      }
    },
  },
}

export default Vue.extend(options)
