
import Vue from 'vue'
import type { PropOptions } from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import type { StringDate } from 'types/constantType'

interface Data {
  token: string
}
interface Methods {}
interface Computed {}
interface Props {}

const options: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  Data,
  Methods,
  Computed,
  Props
> = {
  filters: {
    formatTime(value?: StringDate): string {
      if (!value) return '-'
      const dt = new Date(value)
      const year = dt.getFullYear()
      const month = dt.getMonth() + 1
      const day = dt.getDate()
      const dayOfweek = ['日', '月', '火', '水', '木', '金', '土'][dt.getDay()]
      return `${year}年${month}月${day}日（${dayOfweek}）`
    },
  },
  props: {
    entrySettlementTimeLimit: {
      type: String,
      default: null,
    } as PropOptions<StringDate>,
    entryPaymentUrl: {
      type: String,
      default: '',
    },
  },
}

export default Vue.extend(options)
